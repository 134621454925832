<template>
  <div class="content">
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
         <vuestic-widget :headerText="'Datos Erroneos'" v-show='status===0'>
          <div class="row">
            <div class="col-md-12">
            <h1>Asegurate de que haz escrito correctamente la URL</h1>
            </div>

          </div>
        </vuestic-widget>
           <vuestic-widget :headerText="'Bienvenido al Evento:'" v-show='status===1'>
          <div class="row">
            <div class="col-md-12">
            <h1>{{name_event}}</h1>
            </div>
          </div>
        </vuestic-widget>
        
        <vuestic-widget :headerText="'Por favor registra tus datos'" v-show='status===1'>
          <form @submit.prevent="saveCustomer">
          <div class="row">
            <div class="col-md-4">
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}"
              >
                <div class="input-group">
                  <input id="name" name="name" v-model="name" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="name"
                  >{{'view.customer.fields.name' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('name')"
                    class="help text-danger"
                  >{{ errors.first('name') }}</small>
                </div>
              </div>




              <!-- OPTIONAL INPUTS -->
              <div class="form-group with-icon-right">
                <div class="input-group">
                  <input id="phone" name="phone" v-model="phone">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="phone"
                  >{{'view.customer.fields.phone' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('phone')"
                    class="help text-danger"
                  >{{ errors.first('phone') }}</small>
                </div>
              </div>



              <div class="form-group with-icon-right">
                <multiselect
                  v-model="gender"
                  deselect-label="Selected Element"
                  label="name"
                  placeholder="Genero"
                  :options="genderoptions"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    Tipo de Cliente:
                    <label style="font-weight:600">{{ option.name }}</label>
                  </template>
                </multiselect>
              </div>


              <!-- END OPTIONAL INPUTS -->


          </div>
          <div class="col-md-4">
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}"
              >
                <div class="input-group">
                  <input id="email" name="email" v-model="email" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="email"
                  >{{'view.customer.fields.email' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('email')"
                    class="help text-danger"
                  >{{ errors.first('email') }}</small>
                </div>
              </div>

                 <div class="form-group with-icon-right">
                <div class="input-group">
                  <input id="address" name="address" v-model="address">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="address"
                  >{{'view.customer.fields.address' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('address')"
                    class="help text-danger"
                  >{{ errors.first('address') }}</small>
                </div>
              </div>

                  <div class="form-group with-icon-right">
                <div class="input-group">
                  <vuestic-date-picker id="date-picker-time" v-model="birthday"/>
                  <label
                    class="control-label"
                    for="date-picker-time"
                  >{{ $t('view.customer.fields.birthday') }}</label>
                  <i class="bar"></i>
                </div>
              </div>
          </div>
          <div class="col-md-4">
            <!--Instagram -->
            <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}"
              >
                <div class="input-group">
                  <input id="instagram" name="instagram" v-model="instagram" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="email"
                  >Indica tu Instagram</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('instagram')"
                    class="help text-danger"
                  >{{ errors.first('instagram') }}</small>
                </div>
              </div>
               <!--Facebook -->
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}"
              >
                <div class="input-group">
                  <input id="facebook" name="facebook" v-model="facebook" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="facebook"
                  >Indica tu Facebook</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('facebook')"
                    class="help text-danger"
                  >{{ errors.first('facebook') }}</small>
                </div>
              </div>
               <!--Twitter -->
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}"
              >
                <div class="input-group">
                  <input id="twitter" name="twitter" v-model="twitter" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="twitter"
                  >Indica tu Twitter</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('twitter')"
                    class="help text-danger"
                  >{{ errors.first('twitter') }}</small>
                </div>
              </div>
          </div>
          </div>
          <div class="row">

           <div class="col-md-6 offset-md-3"> <button
              type="submit"

              class="btn btn-primary btn-sm"
            >Registrar Datos</button></div>

          </div>
        </form>
        </vuestic-widget>
       
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
//import TableCustomer from "../tables/TableCustomer/TableCustomer.vue";

import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "PublicRegister",
  components: {
    SpringSpinner,
    // TableCustomer,
    Multiselect
  },
  mounted() {

    // checkbox and radios\
    var slugevent = this.$route.params.slug;
    this.loadNameEvent(slugevent)
      .then(data => {
         if(data.status==='fail')
        {
          this.status=0;
        }
        this.name_event = data.name;
        this.idevent = data.id;
      })
      .catch(data => {
        console.log(data);

        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
  },
  computed: {
    ...mapState({
    }),
    isSuccessfulNameValid() {
      let isValid = false;
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid;
      }
      return isValid;
    },
    isSuccessEmailValid() {
      let isValid = false;
      if (this.formFields.email) {
        isValid =
          this.formFields.email.validated && this.formFields.email.valid;
      }
      return isValid;
    }
  },
  data() {
    return {
      header: "Nuevo Cliente",
      typecustomeroptions: null,
      code: "",
      name_event:"",
      name: "",
      email: "",
      status:1,
      idevent:"",
      //Optional Inputs
      phone: "",
      address: "",
      gender: "",
      genderoptions: [
        { name: "Mujer", id: 1 },
        { name: "Hombre", id: 2 },
        { name: "Otro", id: 3 }
      ],
      twitter:"",
      facebook:"",
      instagram:"",
      birthday: "",
      //----
      idtypecustomer: "",
      active: 1,
      eventoptions: null
    };
  },
  methods: {
    ...mapActions([
      "loadNameEvent",
      "storeCustomer",
      "addToastMessage"
    ]),
    backPage: function() {
      this.$router.go(-1);
    },
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    saveCustomer() {

      let gender = this.gender? this.gender : 1;
      let email = this.email? this.email : "anonimo"+this.name+"@protaps.io";
      this.storeCustomer({
           // code: this.code,
            name: this.name,
            email: email,
            phone: this.phone,
            address: this.address,
            gender: gender,
            birthday: this.birthday,

            active: this.active,
            id_event: this.idevent,
            id_type_customer: 1,
            instagram:this.instagram,
            facebook:this.facebook,
            twitter:this.twitter
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
             // setTimeout(() => this.$router.replace("/registered/"+data.data.code), 2000);
              setTimeout(() =>  window.open('/registered/'+data.data.code, '_blank'), 2000);
            })
            .catch(data => {
              this.error = data.message;
              this.addToastMessage({
                text: data.message,
                type: "warning"
              });
            });
    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>

